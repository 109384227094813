import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                 
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/116/#" className="blue">
                  {" "}
                  <p>NEUTROGENA<sup>®</sup> Cellular Boost Omlazující oční krém </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NTG-Cellular-Boost-OCNI-krem-Crtn-15-3D3.jpg"
                      alt="NTG Cellular Boost OCNI krem Crtn 15 3D3"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>NEUTROGENA<sup>®</sup> Cellular Boost Omlazující oční krém </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p className="TableParagraph">NOVINKA</p>
                      <p className="TableParagraph">
                        Korekce jemných linek, vějířků a vrásek okolo očí
                        způsobených buněčným vyhořením.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Buněčné vyhoření zpomaluje procesy v buňkách pokožky a
                        je tak jednou z hlavních příčin stárnutí pleti.
                        Důsledkem je ztráta elasticity, vznik vrásek a
                        nejednotný tón pleti. Příčinami buněčného vyhoření jsou
                        zejména UV záření, znečištění ovzduší, stres a únava.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Klinicky prověřené složení krému obsahuje dvě z
                        nejznámějších omlazujících ingrediencí:
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        &nbsp;- <strong>Retinol</strong>, který stimuluje obnovu
                        buněk a zvyšuje produkci vlastního kolagenu a elastinu*
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        -&nbsp; <strong>Kyselinu &nbsp;hyaluronovou</strong>,
                        &nbsp;která &nbsp;pomáhá &nbsp;obnovit ochrannou bariéru
                        pleti a vyplnit vrásky
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Klinicky ověřeno. Pro dosažení nejlepších výsledků
                        používejte každý den.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">
                        Řada NEUTROGENA® Cellular Boost byla vyvinuta ve
                        spolupráci s dermatology. Navrací Vaší pleti energii a
                        uchová její mladistvý vzhled po delší dobu.
                      </p>
                      <p className="TableParagraph">
                        Objevte celou řadu NEUTROGENA® Cellular&nbsp; Boost pro
                        kompletní péči o Vaši pleť.
                      </p>
                      <p className="TableParagraph">&nbsp;</p>
                      <p className="TableParagraph">*Testováno laboratorně.</p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Aplikujte malé množství konečky prstů od vrcholu lícních
                      kostí okolo očí. Pro dosažení nejlepších výsledků
                      používejte každý den. UPOZORNĚNÍ: Přizpůsobte frekvenci
                      používání citlivosti Vaší pleti. V případě nežádoucí
                      reakce přestaňte krém používat.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>15 ml</dd>
                  </dl>
                </div>
                <div id="fb_61"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
